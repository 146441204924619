import MapContainer from './map-container'


export const ZOOM_BACK_THRESHOLD = 10;
export const ZOOM_BACK_WEIGHTS = {
  'wheel': 1,
  'pinch': 11,
  'click': 6
}
const MAP_HEIGHT = 2497
const MAP_WIDTH = 2000
// Top level map
export const WORLD_MAP = new MapContainer(
    'World',
    'jpg',
    [0, 0 , MAP_WIDTH, MAP_HEIGHT],
    [
      new MapContainer(
        'Tetragrammaton',
        'png',
        [0,0,652,657]
      ),
      new MapContainer(
        'Urruneko',
        'png',
        [0,0,1024,1024],
        [
            new MapContainer(
                'Urruneko-Logging-Camp',
                'png',
                [0, 0, 772, 772]
            )
        ]
      ),
      new MapContainer(
        'Oscuro',
        'jpg',
        [0,0,2100,1437]
      ),
      new MapContainer(
        'Port-Merculo',
        'png',
        [0,0,3762,3379]
      ),
      new MapContainer(
        'Chcalco',
        'jpg',
        [0,0,2100,1437]
      ),
      new MapContainer(
        'Athel-Coast',
        'jpg',
        [0, 0, 2048, 1445],
        [
          new MapContainer(
            'Abergrick-Tavern',
            'png',
            [0, 0, 800, 800]
          ),
          new MapContainer(
            'Kettle',
            'png',
            [0, 0, 855, 976]
          )
        ]
      )
    ]
  )
 
  
